<template>
  <div v-if="hasPerm('schools.view_schoollevel')">
    <page-header title="Niveaux scolaires" icon="fa fa-school" :links="getLinks()"></page-header>
    <b-row>
      <b-col cols="5" class="border-right">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b-row>
            <b-col>
              <h3>Niveaux scolaires</h3>
            </b-col>
          </b-row>
          <div class="row-line" :class="undefinedSchoolLevel ? 'selected-line' : ''">
            <div @click="selectSchoolLevel(null)">Niveau scolaire à définir</div>
          </div>
          <div
            v-for="schoolLevel in schoolLevels"
            :key="schoolLevel.id"
            class="row-line"
            :class="isSchoolLevelSelected(schoolLevel) ? 'selected-line' : ''"
            @click="selectSchoolLevel(schoolLevel)"
          >
            <b-row>
              <b-col>
                <span>
                  {{ schoolLevel.fullName }}
                </span>
              </b-col>
              <b-col cols="4" class="text-right">
                <span v-if="schoolLevel.group.id" class="badge badge-dark">
                  {{ schoolLevel.group.name }}
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col v-if="selectedSchoolLevel || undefinedSchoolLevel">
        <div class="school-year-bars">
          Inscrits {{ youthHomeLabel }}:
          <span
            v-for="elt of schoolYears" :key="elt.id"
            class="badge"
            :class="elt === selectedSchoolYear ? 'badge-dark' : 'badge-light'"
            @click.prevent="selectSchoolYear(elt)"
          >
            {{ elt.name }}
          </span>
        </div>
        <loading-gif :loading-name="childrenLoading"></loading-gif>
        <div v-if="!isLoading(childrenLoading)" ref="printMe">
          <div class="no-print the-title">
            <b-row>
              <b-col cols="8">
                <b v-if="selectedSchoolLevel">{{ selectedSchoolLevel.fullName }}</b>
                <b v-else>Niveau scolaire à définir</b>
              </b-col>
              <b-col cols="4" class="text-right">
                <counter-label :counter="elements.length" label="enfant"></counter-label>
              </b-col>
            </b-row>
          </div>
          <div class="hide-here">
            <b-row>
              <b-col cols="8">
                <b v-if="selectedSchoolLevel">{{ selectedSchoolLevel.fullName }}</b>
                <b v-else>Niveau scolaire à définir</b>
              </b-col>
              <b-col cols="3" class="text-right">
                <counter-label :counter="elements.length" label="enfant"></counter-label>
              </b-col>
            </b-row>
          </div>
          <div v-if="elements.length === 0" class="empty-text">
            Aucun enfant pour ce niveau scolaire
          </div>
          <div v-else>
            <b-row v-if="canEdit" class="no-print">
              <b-col>
                <div class="selector-bar">
                  <a
                    v-for="elt of schoolYears" :key="elt.id"
                    href class="btn btn-secondary btn-xs"
                    @click.prevent="selectUpdatedOn(elt)"
                  >
                    Maj en {{ elt.name }}
                  </a>
                  <a href class="btn btn-secondary btn-xs" @click.prevent="selectUpdatedOn(null)">
                    Pas de MAJ
                  </a>
                  <a href class="btn btn-secondary btn-xs" @click.prevent="selectedElements = elements">
                    Tous
                  </a>
                  <a href class="btn btn-secondary btn-xs" @click.prevent="selectedElements = []">
                    Aucun
                  </a>
                </div>
              </b-col>
            </b-row>
          </div>
          <div ref="excelTable">
            <table
              class="table table-bordered table-striped"
              :title="selectedSchoolLevel ? selectedSchoolLevel.fullName : 'à définir'"
            >
              <tr v-for="element in elements" :key="element.child.id">
                <td style="width: 50px">
                  <b-form-checkbox
                    :id="'elt' + element.child.id"
                    :name="'elt' + element.child.id"
                    :value="true"
                    :unchecked-value="false"
                    :checked="isElementSelected(element)"
                    :disabled="!canEdit"
                    @change="onElementSelected($event, element)"
                    class="no-print"
                  ></b-form-checkbox>
                </td>
                <td>
                  {{ element.individual.lastAndFirstName() }}
                </td>
                <td>
                  <span
                    class="badge"
                    :style="badgeStyle(element)"
                    v-if="element.individual.birthDate"
                    @click.prevent="selectAllSameYear(element)"
                  >
                    {{ element.individual.birthDateAsString() }}
                  </span>
                </td>
                <td>
                  <span class="small-text" v-if="element.updatedOn">
                    maj en {{ element.updatedOn.name }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
    <move-school-level-modal
      :all-school-levels="schoolLevels"
      :current-level="selectedSchoolLevel"
      :elements="selectedElements"
      :school-year="selectedSchoolYear"
      @done="selectSchoolLevel($event)"
    >
    </move-school-level-modal>
  </div>
</template>

<script>
import moment from 'moment'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import MoveSchoolLevelModal from '@/components/School/MoveSchoolLevelModal'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeSchoolLevel, makeSchoolYear } from '@/types/schools'
import { makeChild } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { existsIn } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import { compareStrings } from '@/utils/sorting'
import { slugify } from '@/utils/strings'

export default {
  name: 'school-levels-list',
  components: {
    MoveSchoolLevelModal,
    CounterLabel,
    PageHeader,
    LoadingGif,
  },
  mixins: [BackendMixin],
  data() {
    return {
      loadingName: 'school-levels',
      childrenLoading: 'school-levels-children',
      selectedSchoolLevel: null,
      selectedSchoolYear: null,
      schoolLevels: [],
      schoolYears: [],
      elements: [],
      selectedElements: [],
      undefinedSchoolLevel: false,
    }
  },
  async mounted() {
    await this.loadSchoolYears()
    await this.loadSchoolLevels()
  },
  computed: {
    canEdit() {
      return this.hasPerm('families.change_child')
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
  methods: {
    async loadSchoolYears() {
      let url = '/api/active-school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.schoolYears.length) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolLevels() {
      let url = '/api/school-levels/'
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolLevels = resp.data.map(elt => makeSchoolLevel(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    showMoveModal() {
      if (this.selectedElements) {
        this.$bvModal.show('bv-modal-move-school-level')
      }
    },
    async printMe() {
      if (this.selectedSchoolYear) {
        const name = this.selectedSchoolLevel ? this.selectedSchoolLevel.fullName : 'a-definir'
        const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
        const docSlug = 'niveau-scolaire-' + slugify(name)
        const docContent = this.$refs.printMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async excelMe() {
      const name = this.selectedSchoolLevel ? this.selectedSchoolLevel.fullName : 'a-definir'
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'niveau-scolaire-' + slugify(name)
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const links = []
      if (this.canEdit) {
        links.push(
          {
            id: 2,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: (this.elements.length === 0) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 3,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: (this.elements.length === 0) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 1,
            label: 'Changement de niveau',
            callback: this.showMoveModal,
            icon: 'fa fa-school',
            cssClass: (this.selectedElements.length === 0) ? 'btn-primary disabled' : 'btn-primary',
          }
        )
      }
      return links
    },
    isSchoolLevelSelected(schoolLevel) {
      return (this.selectedSchoolLevel && (schoolLevel.id === this.selectedSchoolLevel.id))
    },
    async loadChildren() {
      this.startLoading(this.childrenLoading)
      let levelId = -1
      this.selectedElements = []
      if (this.selectedSchoolLevel) {
        levelId = this.selectedSchoolLevel.id
      } else if (this.undefinedSchoolLevel) {
        levelId = 0
      }
      const schoolYearsMap = new Map()
      for (const schoolYear of this.schoolYears) {
        schoolYearsMap.set(schoolYear.startYear, schoolYear)
      }
      if ((levelId >= 0) && this.selectedSchoolYear) {
        let url = '/api/children-by-school-level/' + this.selectedSchoolYear.id + '/' + levelId + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.elements = resp.data.map(
            elt => {
              return {
                individual: makeIndividual(elt.individual),
                child: makeChild(elt.child),
                updatedOn: schoolYearsMap.get(elt['updated_on']) || null,
              }
            }
          ).sort(
            (elt1, elt2) => compareStrings(
              elt1.individual.lastAndFirstName(), elt2.individual.lastAndFirstName()
            )
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.childrenLoading)
      }
    },
    selectSchoolLevel(schoolLevel) {
      if (schoolLevel && schoolLevel.id) {
        this.undefinedSchoolLevel = false
        this.selectedSchoolLevel = schoolLevel
      } else {
        this.undefinedSchoolLevel = true
        this.selectedSchoolLevel = null
      }
      this.loadChildren()
    },
    selectSchoolYear(schoolYear) {
      this.selectedSchoolYear = schoolYear
      this.loadChildren()
    },
    selectAllSameYear(element) {
      if (this.canEdit && element.individual.birthDate) {
        const selectedIds = this.selectedElements.map(elt => elt.child.id)
        const year = moment(element.individual.birthDate).year()
        for (let elt of this.elements) {
          if (elt.individual.birthDate) {
            const otherYear = moment(elt.individual.birthDate).year()
            if (otherYear === year) {
              if (!existsIn([elt.child.id], selectedIds)) {
                this.selectedElements.push(elt)
              }
            }
          }
        }
      }
    },
    selectUpdatedOn(schoolYear) {
      const selectedIds = this.selectedElements.map(elt => elt.child.id)
      for (const elt of this.elements) {
        if ((elt.updatedOn === schoolYear) && !existsIn([elt.child.id], selectedIds)) {
          this.selectedElements.push(elt)
        }
      }
    },
    onElementSelected(value, elt) {
      if (value) {
        const selectedIds = this.selectedElements.map(elt => elt.child.id)
        if (!existsIn([elt.child.id], selectedIds)) {
          this.selectedElements.push(elt)
        }
      } else {
        const selectedIds = this.selectedElements.map(elt => elt.child.id)
        const index = selectedIds.indexOf(elt.child.id)
        if (index >= 0) {
          this.selectedElements.splice(index)
        }
      }
      this.selectedElements = [].concat(this.selectedElements)
    },
    isElementSelected(elt) {
      const selectedIds = this.selectedElements.map(elt => elt.child.id)
      return existsIn([elt.child.id], selectedIds)
    },
    badgeStyle(student) {
      let background = '#222'
      let color = '#fff'
      if (student.individual.birthDate) {
        const colors = ['#998eff', '#fb8eff', '#ff8b8b', '#8df6ff', '#bffab6', '#fbff8d', '#ffc08d']
        const year = moment(student.individual.birthDate).year()
        const colorIndex = year % colors.length
        background = colors[colorIndex]
        color = '#222'
      }
      return {
        'background-color': background,
        'color': color,
      }
    },
  },
}

</script>

<style scoped>
.row-line {
  cursor: pointer;
}
.selected-line {
  background: #444 !important;
  color: #fff;
}
.border-right{
  border-right: grey 1px solid;
}
.school-year-bars .badge {
  margin-right: 4px;
  cursor: pointer;
}
.school-year-bars, .selector-bar {
  padding: 5px;
  border-bottom: solid 1px #ccc;
}
.selector-bar a {
  display: inline-block;
  margin-right: 5px;
}
.the-title {
  padding: 5px;
  background: #ccc;
  font-weight: bold;
}
</style>
